// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-course-index-js": () => import("./../../../src/pages/course/index.js" /* webpackChunkName: "component---src-pages-course-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-school-coursedetail-js": () => import("./../../../src/pages/school/coursedetail.js" /* webpackChunkName: "component---src-pages-school-coursedetail-js" */),
  "component---src-pages-school-jcsj-js": () => import("./../../../src/pages/school/jcsj.js" /* webpackChunkName: "component---src-pages-school-jcsj-js" */),
  "component---src-pages-school-wmdjs-js": () => import("./../../../src/pages/school/wmdjs.js" /* webpackChunkName: "component---src-pages-school-wmdjs-js" */),
  "component---src-pages-toy-index-js": () => import("./../../../src/pages/toy/index.js" /* webpackChunkName: "component---src-pages-toy-index-js" */)
}


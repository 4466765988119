const routers = [
  {
    name: '首页',
    link: '/',
  },
  {
    name: '早教课程',
    link: '/school/coursedetail',
  },
  {
    name: '我们的教室',
    link: '/school/wmdjs',
  },
  {
    name: '精彩瞬间',
    link: '/school/jcsj',
  },
  {
    name: '课件下载',
    link: 'http://dl.tunbaobao.com/kejian/index.html',
  },
  {
    name: '托育平台',
    link: 'https://course.timetbb.com',
  },
  {
    name: '联系我们',
    link: '/contact',
  },
];

export default routers;
